.parentContainer {
    background: #222;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.container {
    width: 100%;
    max-width: 1920px;
    min-height: 1200px;
    padding-top: 75px;
    padding-left: 150px;
    position: relative;
}
.backGround {
    position: absolute;
    top: 195px;
    right: 100px;
    max-width: 50%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.footerBGKeyboard {
    max-width: 900px;
    max-height: 300px;
}
.footerBGUSB {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    max-width: 449px;
    max-height: 250px;
}
.ThreeComp {
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-items: flex-end;
}
.footerBGKEYCAP {
    max-width: 80px;
    max-height: 350px;
}
.footerBGScrew {
    max-width: 30px;
    max-height: 306px;
}
.footerBG {
    max-width: 127px;
    max-height: 204px;
}
.title {
    color: #fff;
    text-align: left;
    font-family: Poppins;
    font-size: 65px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    margin-bottom: 75px;
}
.subtitle {
    color: #fff;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.subtitleCont {
    color: #fff;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
}
.ListSpecs {
    color: #fff;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.ListSpecs:nth-child(2) {
    font-weight: 700;
    font-size: 30px;
}
.Specs {
    margin-bottom: 70px;
}
.TwoBox {
    display: flex;
    gap: 250px;
}
.boxTitle {
    color: #fff;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.BoxContent {
    color: #fff;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and  (max-width: 1450px) {
    .backGround {
        position: absolute;
        top: 195px;
        right: 100px;
        max-width: 50%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
    }
    .footerBGKeyboard {
        max-width: 600px;
        max-height: 200px;
    }
    .footerBGUSB {
        width: 100%;
        height: 100%;
        max-width: 249px;
        max-height: 162px;
    }
    .ThreeComp {
        display: flex;
        justify-content: space-between;
        width: 80%;
        align-items: flex-end;
    }
    .footerBGKEYCAP {
        max-width: 40px;
        max-height: 205px;
    }
    .footerBGScrew {
        max-width: 20px;
        max-height: 170px;
    }
    .footerBG {
        max-width: 104px;
        max-height: 124px;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1120px) {
    .container {
        padding: 60px;
    }
    .footerBGKeyboard {
        max-width: 490px;
        max-height: 200px;
    }
    .backGround {
        right: 80px;
    }
}
@media screen and (max-width: 650px) {
    .container {
        padding: 0px 20px;
        min-height: max-content;
        padding-bottom: 50px;
        margin-top: 100px;
    }
    .title {
        font-size: 40px;
        margin-bottom: 30px;
    }
    .subtitle {
        font-size: 20px;
    }
    .ListSpecs {
        font-size: 15px;
    }
    .ListSpecs:nth-child(2) {
        font-weight: 700;
        font-size: 20px;
    }
    .Specs {
        margin-bottom: 30px;
    }
    .subtitleCont {
        font-size: 18px;
    }
    .TwoBox {
        gap: 20px;
    }
    .boxTitle {
        font-size: 20px;
    }
    .BoxContent {
        font-size: 18px;
    }
}

@media screen and (max-width: 650px) {
  .backGround {
      position: absolute;
      top: 75px;
      right: 10px;
      max-width: 50%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0px;
  }
  .footerBGKeyboard {
      display: none;
  }
  .footerBGUSB {
      width: 100%;
      height: 100%;
      max-width: 109px;
      max-height: 70px;
      margin-right: 20px;
  }
  .ThreeComp {
      display: flex;
      justify-content: space-between;
      width: 70%;
      align-items: flex-end;
      min-height: fit-content;
      padding: 15px 0px;
      margin-right: 20px;
  }
  .footerBGKEYCAP {
      max-width: 40px;
      max-height: 95px;
  }
  .footerBGScrew {
      max-width: 20px;
      max-height: 70px;
  }
  .footerBG {
      max-width: 104px;
      max-height: 44px;
  }
}


@media screen and (min-width: 650px) and (max-width: 1000px) {
  .container{
    padding: 40px;
  }
  .backGround {
      position: absolute;
      top: 145px;
      right: 25px;
      max-width: 50%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0px;
  }
  .footerBGKeyboard {
      display: none;
  }
  .footerBGUSB {
      width: 100%;
      height: 100%;
      max-width: 209px;
      max-height: 100px;
  }
  .ThreeComp {
      display: flex;
      justify-content: space-between;
      width: 70%;
      align-items: flex-end;
      min-height: fit-content;
      padding: 15px 0px;
  }
  .footerBGKEYCAP {
      max-width: 40px;
      max-height: 195px;
  }
  .footerBGScrew {
      max-width: 20px;
      max-height: 170px;
  }
  .footerBG {
      max-width: 104px;
      max-height: 100px;
  }
}