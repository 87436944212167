.ParentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: #222;
    position: relative;
    z-index: 1;
}
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding-top: 70px;
    margin-bottom: 20px;
}
.overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 38.54%,
        rgba(0, 0, 0, 0.7) 100%
    );
    position: absolute;
    border-radius: 15px;
    border: none !important;
}

.TopRow1,
.TopRow2,
.middleRowCenter,
.middleRightBottom,
.bottomRowImgLeft,
.bottomRowImgMiddle,
.bottomRowImgRight {
    border: 0.5px solid #7f7f7f;
}
.GalleryImages > div > div > div {
    border: 0.5px solid #7f7f7f;
}
.middleLeftRowTop {
    border: none !important;
}
.middleLeftRowTop > div {
    border: 0.5px solid #7f7f7f;
}
.bottomRowImgLeft,
.bottomRowImgMiddle,
.bottomRowImgRight,
.middleRowCenter,
.middleRightBottom,
.middleLeftRowTop > div {
    background-color: #222;
}
.title {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 50px;
    max-width: 1920px;
}
.GalleryImages {
    width: 100%;
    max-width: 100%;
    height: max-content;
    padding: 30px;
    margin-bottom: 20px;
    overflow: hidden;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.GalleryImageContainer {
    width: 100%;
    background: #2e2f33;
    display: flex;
    justify-content: center;
}
.GalleryImages img:global {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}
.topRow {
    display: flex;
    margin-bottom: 10px;
    max-width: 100%;
    justify-content: space-between;
}
.TopRow1 {
    width: 30%;
    height: 200px;
    max-height: 200px;
    position: relative;
    object-fit: cover;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.TopRow2 {
    width: 18%;
    height: 200px;
    max-height: 200px;
    position: relative;
    object-fit: contain;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.middleRow {
    display: flex;
    gap: 20px;
    height: 390px;
    justify-content: space-between;
}
.middleLeftRow {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.middleLeftRowTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 20%;
    border-radius: 15px;
}
.imgMiddleRowLeft {
    position: relative;
    width: 48%;
    height: 100%;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.middleRowCenter {
    position: relative;
    width: 55%;
    height: 390px;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
    padding: 1rem 2rem;
}
.middleRowCenter img:global {
    object-fit: contain;
}
.imgMiddleRowLeftBottom {
    position: relative;
    width: 100%;
    height: 75%;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.middleRightRow {
    width: 25%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
}
.middleRightTop {
    position: relative;
    width: 100%;
    height: 75%;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.middleRightBottom {
    position: relative;
    height: 20%;
    width: 100%;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.bottomRow {
    width: 100%;
    display: flex;
    height: 200px;
    justify-content: space-between;
}
.bottomRowImgLeft {
    position: relative;
    width: 23.2%;
    height: 100%;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.bottomRowImgRight {
    width: 23.2%;
    height: 100%;
    position: relative;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.bottomRowImgMiddle {
    position: relative;
    width: 16%;
    height: 100%;
    box-shadow: inset 0px -6px 6px -6px #000;
    border-radius: 15px;
}
.bottomRowImgMiddle img:global {
    margin-top: 5px;
    height: 75%;
    object-fit: contain;
    padding: 0.7rem 3rem;
}
.ImageText {
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: 100%;
    text-align: center;
    font-size: 17px;
    line-height: 18px;
    font-family: Poppins;
    color: #fff;
    z-index: 2;
    padding: 5px;
}
.iconImageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: 100%;
    padding: 0px 5px;
}
.iconImage {
    width: 28% !important;
    height: 80% !important;
    object-fit: contain !important;
    padding: 5px;
}
.iconImageText {
    width: 70%;
    height: 100%;
    align-items: center;
    display: flex;
    font-size: 16px;
    color: #fff;
    font-family: Poppins;
}
.ImageTextGasket {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 900;
    color: transparent;
    background: linear-gradient(90deg, #cdd3ea, #f9c2d9);
    -webkit-background-clip: text;
    background-clip: text;
}
.ImageTextFrame {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 800;
    color: transparent;
    background: linear-gradient(90deg, #cdd3ea, #f9c2d9);
    -webkit-background-clip: text;
    background-clip: text;
}
.ImageTextFrame span:global {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-top: 15px;
}

@media screen and (max-width:1020px) {
    .ParentContainer{
        display: none;
    }
}