.FloatingCTAContainer {
    position: fixed;
    bottom: 50px;
    right: 30px;
    border-radius: 15px;
    text-decoration: none;
    color: #fff;
    font-family: 'Poppins';
    font-size: 20px;
    padding: 10px 10px;
    z-index: 10;
    overflow: hidden;
    width: 73px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
    gap: 15px;
    background: #fe894d;
}
.FloatingCTAContainer:hover {
    width: 200px;
    cursor: pointer;
    user-select: none;
}
.FloatingCTAContainer:active {
    box-shadow: none;
}
.FloatingCTAContainer span:global {
    min-width: max-content;
}
.FloatingCTAContainer img:global {
    /* filter: drop-shadow(0px 0px 10px #000); */
}

@media screen and (max-width: 650px) {
    .FloatingCTAContainer {
        overflow: unset;
        background: #fe894d;
        width: max-content;
        padding: 10px 15px;
        bottom: 20px;
        font-size: 15px;
        gap: 15px;
        border: 1px solid #fff;
        left: unset;
        right: -90px;
    }
    .FloatingCTAContainer:hover {
        width: max-content;
    }
    .openCTA {
        right: 20px !important;
    }

    .FloatingCTAContainer img:global {
        width: 30px;
        height: 30px;
    }
}
