.container {
    background-image: url("/public/stars-bg.webp");
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 75px;
    flex-direction: column;
    align-items: center;
}
.subContainer {
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.brandImage {
    margin-bottom: 32px;
}
.brandText {
    color: #fff;
    text-align: center;
    text-shadow: 0px 2px 2.5px rgba(255, 255, 255, 0.1);
    font-family: Raleway;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 7px;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.brandTextLeft {
    margin-right: 50px;
    color: #c1e4dc;
}
.subTitle {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 18px;
}
.keyboard {
    width: 90%;
}

@media screen and (max-width:650px) {
    .container{
        padding: 30px;
    }
    .brandImage{
        width: 100px;
        height: 67px;
        margin-bottom: 20px;
    }
    .brandText{
        font-size: 40px;
        margin-bottom: 10px;
        letter-spacing: 3px;
    }
    .brandTextLeft{
        margin-right: 20px;
    }
    .subTitle{
        font-size: 20px;
    }
}