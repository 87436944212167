.parentContainer {
    width: 100%;
    box-sizing: border-box;
    /* height: 1515px; */
    display: flex;
    justify-content: center;
}
.container {
    width: 100%;
    max-width: 1920px !important;
    padding: 70px 190px;
    max-width: 100%;
}
.toptobottom {
    background: linear-gradient(180deg, #222 80%, #2e2f33 20%);
}
.bottomtotop {
    background: linear-gradient(360deg, #222 80%, #2e2f33 20%);
}
.Row {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    background: #222;
    border-radius: 15px;
}
.Row {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.DescriptionText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 73px;
}
.FrostSection {
    width: 100%;
    object-fit: cover;
    height: 550px;
    max-height: 550px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 170px;
}
.FrostSectionImage {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
    min-height: 550px;
    border-radius: 15px;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.5));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    border-radius: 10px;
}
.TextContainer {
    position: absolute;
    bottom: 40px;
    left: 79px;
}
.TextTitleArion {
    color: #fff;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
}
.textSubtitleArion {
    display: block;
    color: #f6f6f6;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    line-height: 32.625px;
    max-width: 850px;
    position: relative;
    z-index: 1;
}
.textSubtitleArion b:global {
    font-weight: 800;
}
.twoCards {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}
.cards {
    position: relative;
    background-color: #fff; /* Optional background color */
    box-sizing: border-box;
    border-radius: 20px;
    width: 700px;
    height: 700px;
}
.cardOverlay {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 38.54%,
        rgba(0, 0, 0, 0.9) 100%
    );

    border-radius: 19px;
}
.cardText {
    display: block;
    width: 100%;
    color: #f9c3d9;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 50px */
    position: absolute;
    bottom: 30px;
    z-index: 1;
}
.cardText:nth-child(2) {
    color: #bfe4dc;
}
.cardImage {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    border-radius: 20px;
    min-width: 100%;
    background-color: white;
    object-fit: cover;
}
@media screen and (max-width: 1300px) {
    .container {
        padding: 70px 125px;
    }
    .FrostSection {
        height: 400px;
        max-height: 400px;
    }
    .FrostSectionImage {
        min-height: 400px;
    }
}

@media screen and (max-width: 1800px) {
    .cards {
        width: 550px;
        height: 550px;
    }
}
@media screen and (max-width: 1500px) {
    .cards {
        width: 500px;
        height: 500px;
    }
}
@media screen and (max-width: 1410px) {
    .cards {
        width: 450px;
        height: 450px;
    }
}
@media screen and (max-width: 1170px) {
    .cards {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 1100px) {
    .container {
        padding: 30px;
    }
    .twoCards {
        gap: 50px;
        height: 400px;
    }
    .parentContainer {
        height: 1250px;
    }
    .DescriptionText {
        font-size: 25px;
    }
    .TextTitleArion {
        font-size: 60px;
    }
    .textSubtitleArion {
        font-size: 20px;
        line-height: 25px;
        max-width: 600px;
    }
}

@media screen and (max-width: 650px) {
    .parentContainer {
        height: max-content;
    }
    .container {
        padding: 20px;
    }
    .DescriptionText {
        font-size: 15px;
        margin-bottom: 30px;
    }
    .TextTitleArion {
        font-size: 30px;
    }
    .textSubtitleArion {
        font-size: 15px;
        line-height: 18px;
        max-width: 600px;
    }
    .FrostSection {
        max-height: 300px;
        overflow: hidden;
        margin-bottom: 70px;
    }
    .FrostSectionImage {
        object-fit: cover;
        min-height: 100%;
    }
    .TextContainer {
        position: absolute;
        bottom: 40px;
        left: 20px;
    }
    .twoCards {
        flex-wrap: wrap;
        justify-content: center;
        height: max-content;
    }
}

@media screen and (max-width: 375px) {
    .textSubtitleArion {
        font-size: 13px;
    }
}
