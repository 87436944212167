.multicolumnParent {
  width: 100%;
  display: flex;
  background-color: #2e2f33;
  justify-content: center;
  padding: 100px 125px 50px;
}
.Container {
  max-width: 1455px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3vw;
  flex-wrap: wrap;
}
.Column {
  max-width: 125px;
  min-width: 125px;
  width: 8.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 300px;
}
.colImage {
  width: 126px;
  height: 125px;
  min-height: 125px;
  margin-bottom: 32px;
  border: 2px solid #fff;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.colImage img:global{
  object-fit: contain;
  height: 100px;
  width: 100px;
}
.title {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 50px */
  margin-bottom: 16px;
}
.description {
  color: #959595;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 105px;
  width: max-content;
}
@media screen and (max-width:650px) {
  .Container{
    gap: 15%;
  }
  .multicolumnParent{
    padding-top: 50px;
  }
  .title{
    margin-bottom: 10px;
  }
  .description{
    margin-bottom: 30px;
  }
  .colImage{
    margin-bottom: 20px;
  }

}