@font-face {
  font-family: 'Poppins';
  src: url('/public/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/public/Raleway/Raleway-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  box-sizing: border-box;
}
.meckeys-parent-container{
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #222 0%, #2E2F33 100%);
  min-height: 100%;
  overflow-x: hidden;
}
body{
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #222 0%, #2E2F33 100%);
}